<template>
  <div class="birthdayRight">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>生日礼遇</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>商品分类：</span>
      <el-select
        v-model="formData.prodType"
        clearable
        @clear="onClear(1)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button type="primary" class="btn" @click="getTableList()"
        >查询</el-button
      >

      <el-button
        type="primary"
        class="btn"
        @click="dialogVisible = true"
        v-if="$store.state.powerList.indexOf('userDay:right:list:add') !== -1"
      >
        添加商品
      </el-button>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="rightId" label="权益Id"> </el-table-column>
      <el-table-column prop="prodName" label="产品名称"> </el-table-column>
      <el-table-column prop="prodType" label="产品类型"> </el-table-column>
      <el-table-column prop="discount" label="折扣"> </el-table-column>
      <el-table-column label="设置时间" width="120">
        <template slot-scope="scope">{{ scope.row.addTime }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            v-if="
              $store.state.powerList.indexOf('userDay:right:list:delete') !== -1
            "
            title="确认删除该产品吗？"
            @confirm="toDelete([scope.row])"
          >
            <el-button type="danger" size="mini" slot="reference">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            v-if="
              $store.state.powerList.indexOf('userDay:right:list:edit') !== -1
            "
            @click="showEdit(scope.row)"
          >
            修改折扣
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>

    <div class="btn_box">
      <el-popconfirm
        title="确认批量删除该产品吗？"
        v-if="
          $store.state.powerList.indexOf('userDay:right:list:delete') !== -1
        "
        @confirm="toDelete(multipleSelection)"
      >
        <el-button type="primary" slot="reference">批量删除</el-button>
      </el-popconfirm>
    </div>

    <el-dialog title="选择产品" :visible.sync="dialogVisible" width="70%">
      <product-box
        v-if="dialogVisible"
        @addData="onBackData"
        @close="dialogVisible = false"
      ></product-box>
    </el-dialog>

    <el-dialog
      title="会员日产品折扣"
      :visible.sync="dialogVisible2"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="	一级分销比例">
          <el-input-number
            :min="0"
            :step="0.1"
            :max="1"
            :step-strictly="true"
            placeholder="请输入折扣:0~1"
            v-model="addForm.discount"
          />
        </el-form-item>
      </el-form>
      <div class="tips">折扣范围: 0~1</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="toAddProduct()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑产品折扣" :visible.sync="dialogVisible3" width="50%">
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="	一级分销比例">
          <el-input-number
            :min="0"
            :step="0.1"
            :max="1"
            :step-strictly="true"
            placeholder="请输入折扣:0~1"
            v-model="editForm.discount"
          />
        </el-form-item>
      </el-form>
      <div class="tips">折扣范围: 0~1</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="toEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import productBox from "./productBox";
import {
  birthdayProductList,
  birthdayProductAdd,
  birthdayProductDel,
  birthdayProductEdit,
} from "../../api/memberRight.js";
export default {
  name: "birthdayRight",
  components: {
    productBox,
  },
  data() {
    return {
      memberDay: 1,
      typeList: [
        { id: undefined, name: "全部" },
        { id: 1, name: "客房" },
        { id: 2, name: "门票" },
        { id: 3, name: "线路" },
      ],
      editForm: {
        rightId: "",
        discount: "",
      },
      addForm: {
        prodType: "",
        prodId: "",
        discount: "",
      },
      formData: {
        prodType: null,
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      tableData: [],
      multipleSelection: [],
      gradeList: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
    };
  },
  created() {
    this.checkBirthday();
    this.getTableList();
  },
  methods: {
    async getTableList() {
      if (!(this.formData.prodType >= 0)) this.formData.prodType = undefined;
      const { data } = await birthdayProductList(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    onBackData(arr, type) {
      console.log(arr, type);
      this.addForm.prodId = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
      this.addForm.prodType = type;
      this.dialogVisible2 = true;
    },
    async toAddProduct() {
      if (this.addForm.discount == "")
        return this.$message.error("请将信息填写完整！");
      const { data } = await birthdayProductAdd(this.addForm);
      if (data.code == 0) {
        this.dialogVisible = false;
        this.dialogVisible2 = false;
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async toDelete(list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的会员！");
      }
      let prodId = list
        .map((item) => {
          return item.rightId;
        })
        .join(",");
      const { data } = await birthdayProductDel({ prodId });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    async showEdit(e) {
      this.editForm.rightId = e.rightId;
      this.editForm.discount = e.discount;
      this.dialogVisible3 = true;
    },
    async toEdit() {
      for (var k in this.editForm) {
        if (this.editForm[k] == "")
          return this.$message.error("请填写完整信息!");
      }
      const { data } = await birthdayProductEdit(this.editForm);
      if (data.code == 0) {
        this.dialogVisible3 = false;
        this.getTableList();
        return this.$message.success(data.msg);
      }
      this.$message.error(data.msg);
    },
    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.birthdayRight {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .tips {
    // text-align: right;
    color: orangered;
  }
  .grade_list {
    padding: 0 10px 10px;
    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .form_item {
        display: flex;
        .el-form-item {
          flex: 1;
          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>